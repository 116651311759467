<template>
  <nav
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow shadow-gray-100">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <NuxtLink to="/admin/management/orders">
          <nuxt-img src="/img/logo.png" class="h-16 p-1" alt="Jú Peixoto Doceria" />
        </NuxtLink>
        <button class="cursor-pointer text-xl leading-none px-3 py-1 bg-transparent block lg:hidden" type="button"
          @click="setNavbarOpen">
          <Component :is="MenuLineIcon" class="w-6 h-6 fill-current text-ber" />
        </button>
      </div>
      <div class="mt-4 sm:mt-0 lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']">
        <ul class="flex flex-col items-center lg:flex-row list-none lg:ml-auto gap-4">
          <li v-for="item in sidebar" :key="item.name" class="relative">
            <NuxtLink v-if="!item.submenu && item.name !== 'Sair'" :to="item.url"
              class="flex gap-x-4 items-center py-2 text-gray-500 hover:text-pink-jpd-400" @click="setNavbarOpen">
              <span>{{ item.name }}</span>
            </NuxtLink>
            <button v-if="item.name === 'Sair'" @click="item.action"
              class="flex gap-x-4 items-center py-2 text-gray-500 hover:text-pink-jpd-400">
              <span>{{ item.name }}</span>
            </button>
            <div v-else-if="item.submenu">
              <button @click="item.open = !item.open"
                class="flex gap-x-4 items-center py-2 text-gray-500 hover:text-pink-jpd-400">
                <span>{{ item.name }}</span>
              </button>
              <ul v-if="item.open" class="absolute bg-white shadow-lg rounded mt-2 w-48 z-10">
                <li v-for="subItem in item.submenu" :key="subItem.name" @click="setNavbarOpen">
                  <NuxtLink :to="subItem.url" class="block px-4 py-2 text-gray-500 hover:text-pink-jpd-400">
                    {{ subItem.name }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Websocket :token="auth.token" :identifier="'MANAGEMENT_ORDER'" />
  </nav>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useAuthStore } from "~~/stores/auth";
import LogoutIcon from "../../assets/svg/logout-box-line.svg";
import MenuLineIcon from '../../assets/svg/menu-line.svg';
import ProductIcon from "../../assets/svg/cake-2-line.svg";
import CategoryIcon from "../../assets/svg/grid-fill.svg";
import ShoppingBagIcon from "../../assets/svg/shopping-bag-line.svg";
import TimeIcon from "../../assets/svg/time-line.svg";

const navbarOpen = ref(false);
const auth = useAuthStore();
const baseUrl = '/admin/';
const sidebar = reactive([
  { name: "Pedidos", url: `${baseUrl}management/orders`, icon: ShoppingBagIcon },
  {
    name: "Produtos",
    icon: ProductIcon,
    open: false,
    submenu: [
      { name: "Produtos", url: `${baseUrl}management/products`, icon: ProductIcon },
      { name: "Categorias", url: `${baseUrl}management/categories`, icon: CategoryIcon },
      { name: "Tamanhos", url: `${baseUrl}management/sizes`, icon: CategoryIcon },
      { name: "Sabores", url: `${baseUrl}management/flavors`, icon: CategoryIcon },
      { name: "Complementos", url: `${baseUrl}management/complements`, icon: CategoryIcon },
    ]
  },
  {
    name: "Configurações",
    icon: ProductIcon,
    open: false,
    submenu: [
      { name: "Prazo de entrega", url: `${baseUrl}management/delivery-time`, icon: TimeIcon },
      { name: "Status dos pedidos", url: `${baseUrl}management/order-status`, icon: TimeIcon },
    ]
  },
  { name: "Sair", url: `${baseUrl}login`, icon: LogoutIcon, action: logout },
]);

// Força status do menu para ser fechado ao clicar nos links do dropdown
watch(navbarOpen, () => {
  if (!navbarOpen.value) {
    sidebar.forEach(item => {
      if (item.submenu) {
        item.open = false;
      }
    });
  }
});

function setNavbarOpen() {
  navbarOpen.value = !navbarOpen.value;
}

async function logout() {
  // Limpa cookies auth
  auth.clear();
  reloadNuxtApp();
}
</script>

<style scoped>
.router-link-exact-active {
  color: #D95F69;
}
</style>
